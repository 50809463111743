import clsx from 'clsx'
import { isNil } from 'lodash'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent as AdminMainContent } from 'admin/components/layout/MainContent'
import { isLoanServicing, pathTo as adminPathTo } from 'admin/path-to'
import { MainContent as BorrowerMainContent } from 'borrower/components/layout/MainContent'
import { pathTo as borrowerPathTo } from 'borrower/path-to'
import { BadgeBudgetDrawStatus } from 'components/Badge'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/Button'
import { ButtonGroup } from 'components/ButtonGroup'
import { DrawerActivities } from 'components/Drawer/Activities'
import { DrawerPreviewDraw } from 'components/Drawer/PreviewDraw'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalPayment } from 'components/Modal/Payment'
import { ModalSendForRevision } from 'components/Modal/SendForRevision'
import { Panel } from 'components/Panel'
import { TableDrawBudgetItems } from 'components/TableDrawBudgetItems'
import { LoanStatus } from 'constants/loan-status'
import { useMemoOnceWhen } from 'hooks/use-hook-once'
import { useBudget } from 'hooks/use-loan-budget'
import {
  useBudgetDraw,
  useSubmitBudgetDraw,
  useUpdateBudgetDraw,
} from 'hooks/use-loan-budget-draw'
import { useLoan } from 'hooks/use-loans'
import { BudgetItem, BudgetDraw, Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate, friendlyDate } from 'utils/date'
import { sumDecimal } from 'utils/math'
import { message } from 'utils/message'
import { isAdminRoute } from 'utils/routes'
import styles from './styles.module.scss'

const LoanBudgetDraw = () => {
  const navigate = useNavigate()
  const isLender = isAdminRoute()
  const { loanId, addressId, drawId } = useParams() as {
    loanId: string
    addressId: string
    drawId: string
  }
  const [budgetItemId, setBudgetItemId] = useState<string>()
  const [isPreviewVisible, setIsPreviewVisible] = useState(false)
  const [isRevisionModalVisible, setIsRevisionModalVisible] = useState(false)
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false)
  const [isSendConfirmationVisible, setIsSendConfirmationVisible] =
    useState(false)
  const [rollbackConfirmation, setRollbackConfirmation] = useState({
    isVisible: false,
    newStatus: '',
  })
  const { data: loan, isPending: isLoanLoading } = useLoan({ id: loanId })
  const { data: budget, isPending: isBudgetLoading } = useBudget(
    loanId,
    addressId
  )
  const { data: draw, isPending: isDrawLoading } = useBudgetDraw(
    loanId,
    addressId,
    drawId
  )
  const { mutate: updateDraw, isPending: isDrawUpdating } = useUpdateBudgetDraw(
    loanId,
    addressId
  )
  const { mutate: submit, isPending: isSubmitting } = useSubmitBudgetDraw(
    loanId,
    addressId,
    drawId
  )
  const loanPath = isLender
    ? adminPathTo(
        isLoanServicing(loan?.status as LoanStatus)
          ? 'servicingLoanTabEntityId'
          : 'loanTabEntityId',
        loanId,
        'scopeOfWork',
        addressId
      )
    : borrowerPathTo('loanTabEntityId', loanId, 'scopeOfWork', addressId)

  const breadcrumbs = useMemo(
    () => ({
      title: loan?.name as string,
      link: loanPath,
    }),
    [loan?.name]
  )
  const isPending = isLoanLoading || isBudgetLoading || isDrawLoading
  const drawBalance = useMemo(() => {
    if (['Draft', 'Rejected'].includes(draw?.status as BudgetDraw['status'])) {
      return formatUsd(0)
    } else if (draw?.status === 'Approved') {
      return formatUsd(draw?.balance)
    } else if (draw?.status === 'In Review' && isLender) {
      return formatUsd(draw?.balance)
    } else if (draw?.status === 'In Review' && !isLender) {
      return formatUsd(0)
    }
    return formatUsd(draw?.balance)
  }, [isLender, draw?.status, draw?.balance])

  const balanceRemaining = useMemoOnceWhen(
    () => {
      if (!isNil(budget?.balanceRemaining) && !isNil(draw?.amountRequested)) {
        return sumDecimal([
          budget.balanceRemaining,
          draw.status !== 'Rejected' ? draw.amountRequested : 0,
        ])
      }
      return 0
    },
    [budget, draw],
    () => !isNil(budget?.balanceRemaining) && !isNil(draw?.amountRequested)
  )

  const kpis = useMemo(
    () => [
      { name: 'Submitted Date', value: formatDate(draw?.dateSubmitted) || '-' },
      {
        name: 'Total Balance Remaining',
        value: formatUsd(balanceRemaining),
      },
      { name: 'Requested', value: formatUsd(draw?.amountRequested) },
      {
        name: isLender && draw?.status === 'In Review' ? 'Pending' : 'Approved',
        value: drawBalance,
      },
    ],
    [
      draw?.status,
      draw?.dateSubmitted,
      draw?.amountRequested,
      draw?.balance,
      balanceRemaining,
    ]
  )
  const budgetItems = useMemo(
    () =>
      budget?.items.map((item) => ({
        ...item,
        requestedAmount: sumDecimal(
          draw?.budgetActivities
            .filter(({ budgetItemId }) => budgetItemId === item.id)
            .map(({ amountRequested }) => amountRequested)
        ),
        images:
          draw?.budgetActivities
            .filter(({ budgetItemId }) => budgetItemId === item.id)
            .map(({ images }) => images)
            .flat() || [],
      })),
    [budget?.items, draw?.budgetActivities]
  )
  const date = draw?.dateSubmitted || draw?.createdAt
  const isDisabled = useMemo(() => {
    if (isLender) {
      return draw?.status === 'Approved' || draw?.status === 'Released'
    } else {
      return ['In Review', 'Approved', 'Released'].includes(
        draw?.status as BudgetDraw['status']
      )
    }
  }, [isLender, draw?.status])
  const MainContent = isLender ? AdminMainContent : BorrowerMainContent

  return (
    <MainContent
      className={`relative p-4 pt-0 ${isLender ? 'sm:px-8' : 'sm:px-16'} sm:pb-32`}
    >
      {isPending ? (
        <PageLoader />
      ) : (
        <Flex stack gap={32} className={styles.container}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            className={clsx(styles.header, {
              [styles.adminHeader]: isLender,
            })}
          >
            <Breadcrumbs breadcrumbs={breadcrumbs} />

            <Button variant="tertiary" onClick={() => navigate(loanPath)}>
              Save &amp; Exit
            </Button>
          </Flex>

          <Grid gap={16}>
            {kpis.map(({ name, value }) => (
              <Grid.Item sm={12} md={6} lg={3} key={name}>
                <Flex stack gap={8} className={styles.kpi}>
                  <div className={styles.kpiTitle}>{name}</div>
                  <div className={styles.kpiValue}>{value}</div>
                </Flex>
              </Grid.Item>
            ))}
          </Grid>

          <Panel
            title={
              <Flex gap={8} alignItems="center">
                {isLender
                  ? `Review ${friendlyDate(date)} Draw Request`
                  : `${friendlyDate(date)} Draw Request`}
                <BadgeBudgetDrawStatus
                  status={draw?.status as BudgetDraw['status']}
                  reason={draw?.reason as BudgetDraw['reason']}
                />
              </Flex>
            }
            actionChildren={
              isLender ? (
                ['Approved', 'Released'].includes(draw?.status || '') ? (
                  <Flex>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        setRollbackConfirmation({
                          isVisible: true,
                          newStatus: 'In Review',
                        })
                      }
                    >
                      Rollback to Review
                    </Button>
                    <Button
                      disabled={draw?.status === 'Released'}
                      onClick={() => setIsPaymentModalVisible(true)}
                    >
                      Release Payment
                    </Button>
                  </Flex>
                ) : (
                  <Flex>
                    <ButtonGroup variant="default">
                      <Button
                        variant="secondary"
                        onClick={() => setIsRevisionModalVisible(true)}
                      >
                        Send for Revisions
                      </Button>

                      <EllipsesActions
                        trigger={
                          <Button variant="secondary">
                            <Icon name={IconName.arrowDown} />
                          </Button>
                        }
                      >
                        <EllipsesActions.Item
                          icon
                          disabled={draw?.status !== 'In Review'}
                          onSelect={() =>
                            setRollbackConfirmation({
                              isVisible: true,
                              newStatus: 'Draft',
                            })
                          }
                        >
                          <Icon name={IconName.reverse} size="md" />
                          Rollback to Draft
                        </EllipsesActions.Item>
                      </EllipsesActions>
                    </ButtonGroup>
                    <Button onClick={() => setIsPreviewVisible(true)}>
                      Approve
                    </Button>
                  </Flex>
                )
              ) : (
                <Button
                  disabled={isDisabled || !draw?.budgetActivities.length}
                  onClick={() => setIsPreviewVisible(true)}
                >
                  Preview &amp; Send
                </Button>
              )
            }
          >
            <TableDrawBudgetItems
              data={budgetItems}
              onClick={({ id }) => setBudgetItemId(id)}
            />
          </Panel>
        </Flex>
      )}
      {budgetItemId && (
        <DrawerActivities
          loan={loan as Loan}
          addressId={addressId}
          drawId={drawId}
          readonly={isDisabled}
          lender={isLender}
          budgetItem={
            budgetItems?.find(({ id }) => id === budgetItemId) as BudgetItem
          }
          onClose={() => setBudgetItemId(undefined)}
        />
      )}
      {isPreviewVisible && (
        <DrawerPreviewDraw
          loan={loan as Loan}
          addressId={addressId}
          draw={draw as BudgetDraw}
          lender={isLender}
          onConfirm={() => {
            if (isLender) {
              updateDraw({ id: drawId, status: 'Approved' })
            } else {
              setIsSendConfirmationVisible(true)
            }
            setIsPreviewVisible(false)
          }}
          onClose={() => setIsPreviewVisible(false)}
        />
      )}
      {isSendConfirmationVisible && (
        <ModalConfirm
          title="Send Draw Request"
          text="You're about to send this draw request for review. Your request will be reviewed promptly. Once reviewed, you will be notified via email."
          loading={isSubmitting}
          onConfirm={() =>
            submit(undefined, {
              onSuccess: () => setIsSendConfirmationVisible(false),
            })
          }
          onCancel={() => setIsSendConfirmationVisible(false)}
        />
      )}
      {rollbackConfirmation.isVisible && (
        <ModalConfirm
          title="Rollback Draw Request"
          text={`You're about to rollback this draw request to "${rollbackConfirmation.newStatus}".`}
          loading={isDrawUpdating}
          onConfirm={() => {
            if (isLender) {
              updateDraw(
                {
                  id: drawId,
                  status:
                    rollbackConfirmation.newStatus as BudgetDraw['status'],
                },
                {
                  onSuccess: () => {
                    setRollbackConfirmation({
                      isVisible: false,
                      newStatus: '',
                    })
                    message.success('Draw request has been rolled back')
                  },
                }
              )
            }
          }}
          onCancel={() =>
            setRollbackConfirmation({
              isVisible: false,
              newStatus: '',
            })
          }
        />
      )}
      {isRevisionModalVisible && (
        <ModalSendForRevision
          saving={isDrawUpdating}
          onSave={({ message }) =>
            updateDraw(
              { id: drawId, status: 'Rejected', message },
              {
                onSuccess: () => setIsRevisionModalVisible(false),
              }
            )
          }
          onCancel={() => setIsRevisionModalVisible(false)}
        />
      )}
      {isPaymentModalVisible && !!loan && (
        <ModalPayment
          loan={loan}
          type="Funding"
          budgetDrawId={drawId}
          isScopeOfWork
          onCancel={() => setIsPaymentModalVisible(false)}
        />
      )}
    </MainContent>
  )
}

export { LoanBudgetDraw }
