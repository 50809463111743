import clsx from 'clsx'
import { ReactNode, useEffect, useRef } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import Portal from 'components/Modal/Portal'
import { useModal } from 'hooks/use-modal'

interface ModalProps {
  children: ReactNode
  onClose: () => void
  className?: string
  loading?: boolean
}

function ModalFullSize({ children, onClose, loading, className }: ModalProps) {
  const overlay = useRef<HTMLInputElement>(null)
  const modal = useRef<HTMLInputElement>(null)
  useModal({ onClose, overlay, closeOnClickOutside: true })

  // focus first input in the modal
  useEffect(() => {
    const { current: modalEl } = modal
    if (modalEl && !loading) {
      const inputEl = modalEl.querySelector(
        'input:not([placeholder="MM/DD/YYYY"]), textarea'
      ) as HTMLInputElement
      setTimeout(() => inputEl?.focus(), 1)
    }
  }, [loading])

  return (
    <Portal className="fixed inset-0 z-[1]">
      <div ref={overlay} data-esc-closable={true} data-overlay={true} />
      {loading ? (
        <div>
          <Icon name={IconName.loaderSpinner} size="xl" className="spinner" />
        </div>
      ) : (
        <div
          ref={modal}
          className={clsx(
            'h-full bg-white-100 px-10 pt-16 py-7 overflow-auto',
            className
          )}
        >
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            className="absolute right-6 top-6"
          >
            <Button
              variant="ghost"
              className="h-8 w-8 flex-shrink-0 text-grey-600"
              onClick={onClose}
            >
              <Icon name={IconName.closeLarge} size="md" className="h-4 w-4" />
            </Button>
          </Flex>
          <div className="max-w-[1200px] mx-auto">{children}</div>
        </div>
      )}
    </Portal>
  )
}

export { ModalFullSize }
