import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { isBefore, isToday } from 'date-fns'
import { capitalize, compact, isNil, get } from 'lodash'
import { Dispatch, useMemo, SetStateAction } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Badge } from 'components/Badge'
import PaymentStatusBadge from 'components/Badge/PaymentStatusBadge'
import { Checkbox } from 'components/Checkbox'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Tooltip } from 'components/Tooltip'
import { useSession } from 'hooks/use-session'
import { Payment, Payout, Transaction } from 'types'
import { formatAccountNumber } from 'utils/banking'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { getFundingType } from 'utils/loan-transactions'
import styles from './styles.module.scss'

interface Props {
  modifiedTransactions?: Record<string, boolean>
  setModifiedTransactions?: Dispatch<SetStateAction<Record<string, boolean>>>
  selectedCount: number
  checkboxes: {
    master: boolean
    ids: string[]
  }
  hasFilterUrl: boolean
  onClick?: (payout?: Payout) => void
  onCheck: (id: string) => void
  onReverse?: (payout: Payout) => void
  onChangeStatus?: (id: string, isPaidout: boolean) => void
  data?: Payout[]
  loading?: boolean
  isCompleted?: boolean
}

function TablePayouts({
  data = [],
  checkboxes,
  modifiedTransactions,
  setModifiedTransactions,
  hasFilterUrl,
  isCompleted,
  selectedCount,
  onReverse,
  onChangeStatus,
  onCheck,
  onClick,
  loading,
}: Props) {
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    autoInvestorAccounting: undefined,
  })

  const autoInvestorAccounting = settings.autoInvestorAccounting === 'Yes'

  const columns: ColumnDef<Payout>[] = useMemo(
    () =>
      compact([
        {
          header: () => (
            <div className="flex gap-2 -ml-0.5">
              <Checkbox
                checked={checkboxes.master}
                indeterminate={!!checkboxes.ids.length}
                onChange={() => onCheck('master')}
              />
              {selectedCount > 0 ? `${selectedCount} Selected` : 'Date'}
            </div>
          ),
          accessorKey: 'date',
          size: 130,
          cell: ({ getValue, row }) => {
            const { id } = row.original
            const status = row.original.payment?.status
            const isDisabled = !isCompleted && status === 'pending'

            return (
              <div className="flex gap-2 -ml-0.5">
                <Checkbox
                  disabled={isDisabled}
                  checked={
                    !isDisabled &&
                    ((checkboxes.master && !checkboxes.ids.includes(id)) ||
                      (!checkboxes.master && checkboxes.ids.includes(id)))
                  }
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => onCheck(id)}
                />
                {formatDate(getValue() as string)}
              </div>
            )
          },
          meta: {
            plainHeader: 'Date',
          },
        },
        {
          header: 'Loan',
          id: 'loan',
          cell: ({ row }) => {
            const { loan } = row.original
            return (
              loan && (
                <NavLink
                  to={pathTo('servicingLoanTab', loan.id, 'payments')}
                  className="link truncate"
                >
                  {loan.name}
                </NavLink>
              )
            )
          },
        },
        {
          header: 'Recipient',
          accessorKey: 'person',
          cell: ({ getValue, row }) => {
            const { name, id, banking, isFund, isInvestor, person } =
              (getValue() as any) || {}
            return (
              name && (
                <>
                  <NavLink
                    to={pathTo(
                      isFund ? 'fund' : isInvestor ? 'investor' : 'vendor',
                      id
                    )}
                    className="link"
                  >
                    {name}
                  </NavLink>
                  {row.original.payment?.status !== 'pending' &&
                  (!!banking || (!isCompleted && person?.banking)) ? (
                    <>
                      {' '}
                      <Tooltip content="Valid banking info">
                        <Icon
                          name={IconName.dollar}
                          size="md"
                          className={clsx(
                            'w-3 h-3 relative top-[1px]',
                            styles.info
                          )}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <div className="w-[18px]" />
                  )}
                </>
              )
            )
          },
        },
        {
          header: 'Type',
          id: 'type',
          size: 120,
          cell: ({ row }) =>
            getFundingType(row.original as unknown as Transaction),
        },
        {
          header: 'Class',
          accessorKey: 'fundingSourceClass',
          size: 90,
        },
        {
          header: 'Amount',
          accessorKey: 'amount',
          size: 150,
          cell: ({ getValue }) => formatUsd(getValue() as number),
          meta: {
            align: 'right',
          },
        },
        isCompleted
          ? {
              header: 'Destination',
              id: 'destination',
              size: 130,
              cell: ({ row }) => {
                const { banking } = row.original || {}
                return banking
                  ? `${capitalize(banking.accountType)} ${formatAccountNumber(banking.accountNumber)}`
                  : 'Wallet'
              },
            }
          : null,
        {
          header: 'Status',
          size: 90,
          cell: ({ row }) => {
            switch (row.original.payment?.status) {
              case 'cleared':
                return <Badge color="green">Ready</Badge>
              case 'pending':
                return <Badge color="gray">Clearing</Badge>
              default:
                if (!isCompleted) {
                  return (
                    <PaymentStatusBadge
                      status={
                        (row.original?.payment?.status ||
                          'cleared') as Payment['status']
                      }
                    />
                  )
                }
            }

            if (
              row.original.isPaidout ||
              (!autoInvestorAccounting && !row.original.banking)
            ) {
              return <Badge color="green">Paid Out</Badge>
            }
            if (
              isToday(row.original.datePaidout) ||
              isBefore(row.original.datePaidout, new Date())
            ) {
              return <Badge color="gray">Pending</Badge>
            }
            return <Badge color="gray">Scheduled</Badge>
          },
        },
        isCompleted
          ? {
              header: '',
              id: 'isPaidout',
              size: 40,
              cell: ({ row }) => {
                return (!row.original.isPaidout ||
                  !isNil(modifiedTransactions?.[row.original.id])) &&
                  (row.original.banking || autoInvestorAccounting) ? (
                  <Checkbox
                    checked={
                      modifiedTransactions?.[row.original.id] ??
                      row.original.isPaidout
                    }
                    variant="rounded"
                    onChange={() => {
                      setModifiedTransactions?.((prev) => ({
                        ...prev,
                        [row.original.id]: !row.original.isPaidout,
                      }))

                      onChangeStatus?.(row.original.id, !row.original.isPaidout)
                    }}
                  />
                ) : null
              },
            }
          : null,
        isCompleted
          ? {
              header: '',
              accessorKey: 'id',
              size: 40,
              cell: ({ row }) => {
                const { id, isPaidout } = row.original

                return (
                  <EllipsesActions>
                    {autoInvestorAccounting || row.original.banking ? (
                      <EllipsesActions.Item
                        icon
                        onSelect={() => onChangeStatus?.(id, !isPaidout)}
                      >
                        <Icon
                          name={
                            isPaidout
                              ? IconName.minusCircle
                              : IconName.approvedCircle
                          }
                        />
                        Mark as {isPaidout ? 'Pending' : 'Paid Out'}
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                    <EllipsesActions.Item
                      icon
                      onSelect={() => onReverse?.(row.original)}
                    >
                      <Icon name={IconName.reverse} />
                      Reverse
                    </EllipsesActions.Item>
                  </EllipsesActions>
                )
              },
            }
          : null,
      ]),
    [
      selectedCount,
      modifiedTransactions,
      isCompleted,
      checkboxes,
      onCheck,
      onReverse,
    ]
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      noResultsEmptyScreen={hasFilterUrl}
      onClick={onClick}
    />
  )
}

export default TablePayouts
