import clsx from 'clsx'
import { parse, isBefore } from 'date-fns'
import { capitalize } from 'lodash'
import { useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { InlineEditField, InlineSummary } from 'admin/components/InlineEdit'
import { TopMenu } from 'admin/components/TopMenu'
import { useLoanComments } from 'admin/hooks/use-loan-comments'
import { useUpdateLoan } from 'admin/hooks/use-loans'
import { useProducts } from 'admin/hooks/use-products'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { LoanTasksDrawer } from 'admin/pages/Tasks/LoanTasksDrawer'
import { pathTo } from 'admin/path-to'
import {
  LoanStatusBadge,
  LoanSubstatusBadge,
  ServicingLoanStatusBadge,
} from 'components/Badge'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { CommentsDraw } from 'components/Drawer/CommentsDraw'
import { Flex } from 'components/Flex'
import { PageTop } from 'components/PageTop'
import { Summary } from 'components/Summary'
import { loanStatusOptions, LoanStatus } from 'constants/loan-status'
import { Substatus, substatusOptions } from 'constants/substatus'
import { UseOfFunds, useOfFundsOptions } from 'constants/use-of-funds'
import { useUpdateLoanOwners } from 'hooks/use-loans'
import { useMobile } from 'hooks/use-mobile'
import { useSession } from 'hooks/use-session'
import { Loan } from 'types'
import { formatDate } from 'utils/date'
import styles from './LoanHeader.module.scss'
import { LoanHeaderNotification } from './LoanHeaderNotification'
import { LoanImage } from './LoanImage'

interface Props {
  loan: Loan
}

const getBreadcrumbs = (isOrigination: boolean, status: LoanStatus) => {
  if (['archived'].includes(status)) {
    return { title: 'Reports', link: pathTo('reports') }
  }
  return isOrigination
    ? { title: 'Pipeline', link: pathTo('loans') }
    : { title: 'Loans', link: pathTo('servicing') }
}

export const LoanHeader = ({ loan }: Props) => {
  const { isAdmin, isAdminManager } = useSession()
  const {
    isServicing,
    isOrigination,
    isCommentsOpen,
    isTasksOpen,
    openTimeline,
    openComments,
    closeComments,
    openTasks,
    closeTasks,
  } = useLoanContext()
  const { isTablet } = useMobile()
  const [loanStatus, setLoanStatus] = useState(loan.status)
  const { data: products } = useProducts()
  const { mutateAsync: updateLoan } = useUpdateLoan({ id: loan.id })
  const { mutate: updateOwners } = useUpdateLoanOwners()
  const { data } = useLoanComments(
    { loanId: loan.id },
    {
      refetchInterval: isCommentsOpen ? 10000 : 120000,
    }
  )
  const borrower = (loan?.borrowers || []).find(({ borrow }) => borrow.primary)
  const breadcrumbs = getBreadcrumbs(isOrigination, loan.status)
  const handleOwnersChange = useCallback(
    (owners: string[]) => {
      updateOwners({ id: loan.id, owners })
    },
    [loan.id, updateOwners]
  )

  const isMatured =
    loan?.dateMaturity &&
    isBefore(parse(loan.dateMaturity, 'yyyy-MM-dd', new Date()), new Date())

  return (
    <>
      <Flex stack gap={24}>
        <LoanHeaderNotification loan={loan} />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className="md:-mx-4"
        >
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          {isAdmin && !isTablet && (
            <TopMenu
              owners={loan?.owners || []}
              onOwnersChange={handleOwnersChange}
              onTasksClick={openTasks}
              onHistoryClick={isServicing ? openTimeline : undefined}
              onCommentsClick={openComments}
              messagesCount={data?.total}
              readOnly={!isAdminManager}
            />
          )}
        </Flex>
        <div className={styles.headerWrapper}>
          <LoanImage loan={loan} className={styles.loanImage} />
          <Flex className={styles.loanHeader} stack gap={0}>
            <PageTop
              className={styles.pageTop}
              title={loan.name}
              onEdit={(name: string) => updateLoan({ name })}
              hideTopMenu
            />
            <Flex gap={12} className={styles.badges}>
              {['servicing', 'liquidated'].includes(loan.status) ? (
                <>
                  <ServicingLoanStatusBadge
                    status={loan.status}
                    daysPastDue={loan.daysPastDue}
                    daysLate={loan.daysLate}
                    dateDefault={loan.dateDefault}
                  />
                  {isMatured && (
                    <LoanSubstatusBadge substatus="Matured" color="red" />
                  )}
                </>
              ) : (
                <>
                  <InlineEditField
                    type="option"
                    value={loan.status}
                    options={loanStatusOptions.map(({ value, label }) => ({
                      value: value,
                      label: (
                        <div
                          className={clsx(
                            styles.loanStatus,
                            styles[`status${label}`]
                          )}
                        >
                          {label}
                        </div>
                      ),
                    }))}
                    hideEmptyOption
                    format={(value) => <LoanStatusBadge status={value} />}
                    save={(status) => {
                      setLoanStatus(status as LoanStatus)
                      return updateLoan({ status: status as LoanStatus })
                    }}
                  />
                  <InlineEditField
                    type="option"
                    className={styles.substatus}
                    value={loan.substatus}
                    options={substatusOptions
                      .filter(
                        ({ value, status }) =>
                          status === loanStatus || value === loan.substatus
                      )
                      .map(({ value }) => ({
                        value: value,
                        label: (
                          <LoanSubstatusBadge substatus={value as Substatus} />
                        ),
                      }))}
                    hideEmptyOption
                    format={(value, meta) => (
                      <div
                        style={{
                          paddingRight: meta?.status === 'untouched' ? 40 : 0,
                        }}
                      >
                        <LoanSubstatusBadge substatus={value} />
                      </div>
                    )}
                    save={(substatus) =>
                      updateLoan({ substatus: substatus as Substatus })
                    }
                  />
                </>
              )}
            </Flex>
            <div className={styles.headerDataWrapper}>
              <div className={styles.flexItem}>
                {loan.lock ? (
                  <Summary name="ID">{loan.number}</Summary>
                ) : (
                  <InlineSummary
                    type="text"
                    name="ID"
                    value={loan.number}
                    maxLength={20}
                    format={(value) => (
                      <div style={{ whiteSpace: 'nowrap', paddingRight: 20 }}>
                        {value}
                      </div>
                    )}
                    save={(number) => updateLoan({ number: number as string })}
                  />
                )}
              </div>
              <div className={styles.flexItem}>
                <Summary name="Primary Borrower">
                  {borrower ? (
                    <NavLink
                      className="link"
                      to={pathTo(
                        isOrigination ? 'loanTab' : 'servicingLoanTab',
                        loan.id,
                        'borrower'
                      )}
                    >
                      {borrower.name}
                    </NavLink>
                  ) : undefined}
                </Summary>
              </div>
              <div className={styles.flexItem}>
                {loan.lock ? (
                  <Summary
                    name={
                      loan.status === 'servicing'
                        ? 'Origination Date'
                        : 'Closing Date'
                    }
                  >
                    {formatDate(loan.dateClosing)}
                  </Summary>
                ) : (
                  <InlineSummary
                    type="date"
                    name={
                      loan.status === 'servicing'
                        ? 'Origination Date'
                        : 'Closing Date'
                    }
                    value={loan.dateClosing}
                    save={(date) => updateLoan({ dateClosing: date as string })}
                  />
                )}
              </div>
              <div className={styles.flexItem}>
                {loan.lock ? (
                  <Summary name="Product">{loan.product?.name}</Summary>
                ) : (
                  <InlineSummary
                    type="option"
                    name={
                      <Flex gap={6}>
                        Product
                        <NavLink
                          className={clsx('link', styles.linkCustomize)}
                          to={pathTo(
                            isOrigination ? 'loanTab' : 'servicingLoanTab',
                            loan.id,
                            'product'
                          )}
                        >
                          Customize
                        </NavLink>
                      </Flex>
                    }
                    value={loan.product?.id}
                    options={
                      products?.products.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      })) || []
                    }
                    hideEmptyOption
                    format={(value) =>
                      (products?.products.find(({ id }) => id === value) as any)
                        ?.name
                    }
                    save={(productId) =>
                      updateLoan({ productId: productId as string })
                    }
                  />
                )}
              </div>
              <div className={styles.flexItem}>
                {loan.status !== 'servicing' &&
                  (loan.lock ? (
                    <Summary name="Use of Funds">
                      {capitalize(loan.useOfFunds)}
                    </Summary>
                  ) : (
                    <InlineSummary
                      type="option"
                      name="Use of Funds"
                      value={loan.useOfFunds}
                      options={useOfFundsOptions}
                      hideEmptyOption
                      format={(value) => capitalize(value)}
                      save={(useOfFunds) =>
                        updateLoan({ useOfFunds: useOfFunds as UseOfFunds })
                      }
                    />
                  ))}
              </div>
            </div>
          </Flex>
        </div>
      </Flex>
      {isCommentsOpen && (
        <CommentsDraw onClose={closeComments} loanId={loan.id} />
      )}
      {isTasksOpen && <LoanTasksDrawer onClose={closeTasks} />}
    </>
  )
}
