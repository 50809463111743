import { DocusealBuilder } from '@docuseal/react'
import { compact, isNil, last } from 'lodash'
import { useState, useEffect, DragEvent, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import {
  useDeleteDocument,
  useDeleteDocumentVersion,
  useRequestDocument,
  useUpdateDocument,
} from 'admin/hooks/use-document'
import { useInvestor } from 'admin/hooks/use-investors'
import { useSharePerson } from 'admin/hooks/use-share-persons'
import { prefix as urlPrefix } from 'admin/path-to'
import { updateDocument as patchDocument } from 'admin/services/api/document'
import { DragDropFile } from 'components/DragDropFile'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalEditDocument } from 'components/Modal/EditDocument'
import { ModalShareDocument } from 'components/Modal/ShareDocument'
import { Viewer } from 'components/Viewer/Viewer'
import { useBorrower } from 'hooks/use-borrower'
import {
  useDocument,
  useDownloadDocument,
  useDocumentVersion,
  useUploadDocument,
  printDocument,
} from 'hooks/use-document'
import { useLoan } from 'hooks/use-loans'
import { LoanDocument } from 'types'
import { openBrowseFile } from 'utils/file'
import { Header } from './Header'
import { SideMenu } from './SideMenu'
import styles from './styles.module.scss'

type RouterParams = Readonly<{
  offeringId?: string
  fundId?: string
  versionId?: string
  loanId?: string
  personId?: string
  personType?: string
  id: string
}>

const getRedirectUrl = ({
  offeringId,
  fundId,
  personId,
  personType,
  loanId,
  documentId,
  versionId,
  anchor,
}: {
  offeringId?: string
  fundId?: string
  personId?: string
  personType?: string
  loanId?: string
  documentId?: string
  versionId?: string
  anchor?: string
}) => {
  const url: (string | undefined)[] = []
  const isServicing = window.location.pathname.includes('/servicing')
  if (personId) {
    url.push(personType, personId)
  } else if (offeringId) {
    url.push('offerings', offeringId)
  } else if (fundId) {
    url.push('funds', fundId)
  } else if (loanId) {
    url.push(isServicing ? 'servicing' : 'loans', loanId)
  }
  url.push('documents', documentId, versionId, anchor && `#${anchor}`)
  return `${urlPrefix}/${compact(url).join('/')}`
}

function Document() {
  const navigate = useNavigate()
  const {
    offeringId,
    fundId,
    loanId,
    personId,
    personType,
    versionId: routeVersionId,
    id,
  } = useParams() as RouterParams

  const [isDragActive, setIsDragActive] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [isModalShareOpen, setIsModalShareOpen] = useState(false)
  const [esignature, setEsignature] = useState(false)
  const [wait, setWait] = useState<string | undefined>()
  const { data: document, isPending } = useDocument({ id, loanId })
  const { data: loan, isPending: isLoanLoading } = useLoan({
    id: document?.isShared || isModalShareOpen ? loanId : undefined,
  })
  const { data: investor, isPending: isInvestorLoading } = useInvestor(
    {
      id: personId as string,
    },
    {
      enabled:
        personType === 'investors' && (document?.isShared || isModalShareOpen),
    }
  )
  const { data: borrower, isPending: isBorrowerLoading } = useBorrower(
    {
      id: personId as string,
    },
    {
      enabled:
        personType === 'borrowers' && (document?.isShared || isModalShareOpen),
    }
  )

  const isLocked = !!loanId && !!document?.lock?.includes(loanId)

  const versionId =
    routeVersionId || (document?.versions && last(document.versions)?.id)
  const { data: version, isPending: isVersionLoading } = useDocumentVersion({
    documentId: id,
    versionId,
    wait,
  })
  useEffect(() => {
    if (version) {
      setWait(isNil(version.hasThumbnails) ? 'thumbnails' : undefined)
    }
  }, [version])

  const { mutate: deleteDocument } = useDeleteDocument()
  const { mutate: deleteVersion } = useDeleteDocumentVersion(document?.id)
  const { mutate: downloadDocument } = useDownloadDocument({
    versionId,
  })
  const { mutate: uploadDocument } = useUploadDocument()
  const { mutate: updateDocument, isPending: isUpdating } = useUpdateDocument()
  const { mutate: requestDocument } = useRequestDocument()
  const sharePersons = useSharePerson({
    loanId,
    personType,
    loan,
    investor,
    borrower,
    data: document,
    isLoading: isPending,
  })

  const handleRequest = offeringId ? undefined : () => requestDocument(id)
  const handleEdit = (document: Partial<LoanDocument>) => {
    updateDocument({ id, ...document })
  }
  const handleRename = () => setIsModalEditOpen(true)
  const handleShare = () => {
    document?.isShared
      ? updateDocument({ id, isShared: false })
      : setIsModalShareOpen(true)
    setEsignature(false)
  }
  const handleDownload = () => downloadDocument(id)
  const handlePrint = () => {
    printDocument({ id, version })
  }
  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) => {
        uploadDocument(
          { id, name: document?.name, file: files[0] },
          {
            onSuccess: () =>
              navigate(
                getRedirectUrl({
                  loanId,
                  personId,
                  personType,
                  offeringId,
                  fundId,
                  documentId: id,
                })
              ),
          }
        )
      },
    })
  }
  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    uploadDocument(
      { id, name: document?.name, file: files[0] },
      {
        onSuccess: () =>
          navigate(
            getRedirectUrl({
              loanId,
              personId,
              personType,
              offeringId,
              fundId,
              documentId: id,
            })
          ),
      }
    )
  }
  const handleDelete = () => {
    const isDeleteDocument = (document?.versions || []).length < 1
    if (isDeleteDocument) {
      deleteDocument(id)
    } else {
      deleteVersion(versionId as string)
    }
    navigate(
      getRedirectUrl({
        loanId,
        personId,
        personType,
        offeringId,
        fundId,
        documentId: isDeleteDocument ? undefined : id,
      })
    )
  }
  const handleChangeVersion = (versionId: string) =>
    navigate(
      getRedirectUrl({
        loanId,
        personId,
        personType,
        offeringId,
        fundId,
        documentId: id,
        versionId,
      })
    )
  const handleBack = () => {
    navigate(
      getRedirectUrl({
        loanId,
        personId,
        personType,
        offeringId,
        fundId,
        anchor: id,
      })
    )
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }

  const signatureBuilder = useMemo(() => {
    return esignature && document?.esignatureToken ? (
      <DocusealBuilder
        token={document.esignatureToken}
        roles={sharePersons.map(({ name }) => name)}
        withTitle={false}
        withSendButton={false}
        withUploadButton={false}
        withAddPageButton={false}
        withSignYourselfButton={false}
        withDocumentsList={false}
        onSave={async (detail) =>
          patchDocument({ id, esignatureSlug: detail.slug })
        }
      />
    ) : (
      <></>
    )
  }, [esignature])

  return (
    <MainContent className="p-0">
      {!isPending && document ? (
        <div
          className={styles.page}
          onDragEnter={isLocked || esignature ? () => {} : handleDrag}
        >
          {isDragActive && (
            <DragDropFile
              onLeave={() => setIsDragActive(false)}
              onDrop={(files) => handleDrop(files)}
            />
          )}
          <div className={styles.content}>
            <Header
              document={document}
              isLocked={isLocked}
              version={version}
              sharePersons={sharePersons}
              isSignature={esignature}
              onBack={handleBack}
              onEdit={handleEdit}
              onRename={handleRename}
              onShare={offeringId ? undefined : handleShare}
              onDownload={handleDownload}
              onPrint={handlePrint}
              onUpload={handleUpload}
              onDelete={handleDelete}
              onRequest={handleRequest}
              onSignature={
                document?.esignatureToken
                  ? (startShare) => {
                      if (startShare) {
                        setIsModalShareOpen(true)
                      } else {
                        setEsignature(!esignature)
                      }
                    }
                  : undefined
              }
            />
            {esignature && document?.esignatureToken ? (
              signatureBuilder
            ) : (
              <Viewer
                isLocked={isLocked}
                isVersionLoading={
                  isVersionLoading && document.versions.length > 0
                }
                onDownload={handleDownload}
                onUpload={handleUpload}
                version={version}
              />
            )}
          </div>
          <SideMenu
            loanId={loanId}
            document={document}
            version={version}
            isLocked={isLocked}
            onUpload={handleUpload}
            onChangeVersion={handleChangeVersion}
          />
        </div>
      ) : (
        <PageLoader />
      )}
      {isModalEditOpen && (
        <ModalEditDocument
          saving={isUpdating}
          name={document?.name}
          onSave={({ name }) => {
            updateDocument(
              { id, name },
              { onSuccess: () => setIsModalEditOpen(false) }
            )
          }}
          onCancel={() => {
            setIsModalEditOpen(false)
          }}
        />
      )}
      {isModalShareOpen && (
        <ModalShareDocument
          name={document?.name}
          loading={isLoanLoading && isInvestorLoading && isBorrowerLoading}
          sharePersons={sharePersons}
          sharing={isUpdating}
          onShare={({ message }) => {
            updateDocument(
              { id, isShared: true, message: message || undefined },
              { onSuccess: () => setIsModalShareOpen(false) }
            )
          }}
          onCancel={() => setIsModalShareOpen(false)}
        />
      )}
    </MainContent>
  )
}

export { Document }
