import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { compact } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import PaymentStatusBadge from 'components/Badge/PaymentStatusBadge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { TO_BORROWER_PAYMENT_TYPE } from 'services/api/payment'
import { Payment } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'
import { subDecimal, sumDecimal } from 'utils/math'
import { isAdminRoute } from 'utils/routes'
import styles from './styles.module.scss'

type Props = {
  data?: Payment[]
  minWidth?: number
  loading: boolean
  showLoans?: boolean
  showDistributions?: boolean
  showBorrowers?: boolean
  hideTypeColumn?: boolean
  onClickRow?: (payment?: Payment) => void
  onEdit?: (payment: Payment, data: Partial<Payment>) => void
  onDownload?: (payment: Payment) => void
  onDelete?: (payment: Payment) => void
}

function TablePayments({
  data = [],
  loading,
  minWidth = 0,
  showLoans = false,
  showDistributions = false,
  showBorrowers = false,
  hideTypeColumn = false,
  onClickRow,
  onEdit,
  onDownload,
  onDelete,
}: Props) {
  const [expanded, setExpanded] = useState(false)
  const [firstNotReversedPayment, setFirstNotReversedPayment] = useState<
    Payment | undefined
  >()
  const isAdmin = isAdminRoute()
  const isDownloadable = (payment: Payment) => {
    return (
      !['pending', 'reversed'].includes(payment.status) &&
      (!isAdmin ||
        [
          'To Borrower',
          'Regular Payment',
          'Special Payment',
          'Payoff',
        ].includes(payment.type))
    )
  }

  useEffect(() => {
    setFirstNotReversedPayment(
      data.filter((payment) => payment.status !== 'reversed')[0]
    )
  }, [data])

  const columns: ColumnDef<Payment>[] = useMemo(
    () =>
      compact([
        {
          header: 'Date',
          accessorKey: 'date',
          cell: ({ getValue }) => friendlyDate(getValue() as string),
        },
        {
          header: '',
          id: 'notes',
          size: 100,
          cell: ({ row }) =>
            row.original.notes && (
              <Tooltip content={row.original.notes}>
                <Icon
                  name={IconName.note}
                  size="md"
                  className="text-grey-600"
                />
              </Tooltip>
            ),
          meta: {
            align: 'center',
          },
        },
        showLoans && {
          id: 'loan',
          cell: ({ row }) => {
            const { loan } = row.original
            return (
              loan && (
                <NavLink
                  to={pathTo('servicingLoanTab', loan.id, 'payments')}
                  className="link"
                >
                  {loan.name}
                </NavLink>
              )
            )
          },
          header: 'Loan',
        },
        showBorrowers && {
          id: 'borrowers',
          header: 'Borrower',
          cell: ({ row }) =>
            row.original.loan?.borrowers?.map(({ name }) => name).join(', ') ||
            '-',
        },
        !hideTypeColumn && {
          header: 'Type',
          accessorKey: 'type',
          cell: ({ getValue }) => (getValue() as string) || '-',
        },
        showDistributions
          ? {
              header: () => (
                <Flex gap={8} alignItems="center" className={styles.columnName}>
                  Amount
                  <div className={styles.icon}>
                    <Icon
                      name={expanded ? IconName.minus : IconName.plus}
                      size="sm"
                    />
                  </div>
                  <div
                    className={styles.iconWrapper}
                    onClick={() => {
                      setExpanded(!expanded)
                    }}
                  />
                </Flex>
              ),
              id: 'distribution',
              size: 150,
              cell: ({ row }) => {
                const {
                  distribution: {
                    principal,
                    interest,
                    charges,
                    trusts,
                    spread,
                  },
                  source: { trusts: sourceTrusts },
                  type,
                } = row.original
                return (
                  <Text className="font-bold">
                    {formatUsd(
                      type === TO_BORROWER_PAYMENT_TYPE
                        ? sumDecimal([
                            principal,
                            interest,
                            charges,
                            -1 * sourceTrusts,
                            spread,
                          ])
                        : sumDecimal([
                            principal,
                            interest,
                            charges,
                            trusts,
                            spread,
                          ])
                    )}
                  </Text>
                )
              },
              meta: {
                align: 'right',
              },
            }
          : {
              header: 'Principal',
              accessorKey: 'distribution.principal',
              cell: ({ getValue }) => formatUsd(getValue() as string),
            },
        ...(showDistributions && expanded
          ? [
              {
                header: 'Principal',
                accessorKey: 'distribution.principal',
                cell: ({ getValue }) => (
                  <Text className="font-bold">{formatUsd(getValue())}</Text>
                ),
                meta: {
                  align: 'right',
                },
              },
              {
                header: 'Interest',
                accessorKey: 'distribution.interest',
                cell: ({ row }) => {
                  const {
                    distribution: { interest, spread },
                  } = row.original
                  return (
                    <Text className="font-bold">
                      {formatUsd(sumDecimal([interest, spread]))}
                    </Text>
                  )
                },
                meta: {
                  align: 'right',
                },
              },
              {
                header: 'Charges',
                accessorKey: 'distribution.charges',
                cell: ({ getValue }) => {
                  return (
                    <Text className="font-bold">{formatUsd(getValue())}</Text>
                  )
                },
                meta: {
                  align: 'right',
                },
              },
              {
                header: 'Trust',
                accessorKey: 'distribution.trusts',
                cell: ({ getValue, row }) => {
                  const {
                    type,
                    source: { trusts },
                  } = row.original
                  return (
                    <Text className="font-bold">
                      {formatUsd(
                        subDecimal(
                          type === TO_BORROWER_PAYMENT_TYPE ? 0 : getValue()
                        ),
                        trusts
                      )}
                    </Text>
                  )
                },
                meta: {
                  align: 'right',
                },
              },
            ]
          : []),
        {
          header: 'Status',
          accessorKey: 'status',
          size: 105,
          cell: ({ getValue, row }) => (
            <>
              <PaymentStatusBadge
                status={getValue() as Payment['status']}
                isCollected={row.original?.isCollected}
                info={row.original?.notes}
              />
            </>
          ),
        },
        ...(onEdit || onDelete || onDownload
          ? [
              {
                header: '',
                id: 'actions',
                size: 40,
                cell: ({ row }) => {
                  const allowRemove =
                    firstNotReversedPayment?.id === row.original.id
                  return (
                    <EllipsesActions>
                      {onEdit ? (
                        <EllipsesActions.Item
                          icon
                          disabled={
                            row.original.status === 'cleared' ||
                            row.original.isCollected
                          }
                          onSelect={() =>
                            onEdit(row.original, { status: 'cleared' })
                          }
                        >
                          <Icon name={IconName.edit} />
                          Mark as cleared
                        </EllipsesActions.Item>
                      ) : (
                        <></>
                      )}
                      {onEdit ? (
                        <EllipsesActions.Item
                          icon
                          disabled={
                            row.original.status === 'reversed' || !allowRemove
                          }
                          onSelect={() =>
                            onEdit(row.original, { status: 'reversed' })
                          }
                        >
                          <Icon name={IconName.edit} />
                          Reverse{allowRemove}
                        </EllipsesActions.Item>
                      ) : (
                        <></>
                      )}
                      {onDownload ? (
                        <EllipsesActions.Item
                          icon
                          disabled={!isDownloadable(row.original)}
                          onSelect={() => onDownload(row.original)}
                        >
                          <Icon name={IconName.download} />
                          Download receipt
                        </EllipsesActions.Item>
                      ) : (
                        <></>
                      )}
                      {onDelete ? (
                        <EllipsesActions.Item
                          icon
                          onSelect={() => onDelete(row.original)}
                          disabled={
                            (row.original.status !== 'reversed' &&
                              !allowRemove) ||
                            row.original?.isCollected
                          }
                          className="text-red-100"
                        >
                          <Icon name={IconName.delete} />
                          Delete
                        </EllipsesActions.Item>
                      ) : (
                        <></>
                      )}
                    </EllipsesActions>
                  )
                },
              },
            ]
          : []),
      ]),
    [expanded, firstNotReversedPayment]
  )

  return (
    <Table
      className={clsx({
        [showLoans ? styles.tableExpandedWithLoan : styles.tableExpanded]:
          expanded,
      })}
      minWidth={minWidth}
      columns={columns}
      data={data}
      loading={loading}
      onClick={onClickRow}
    />
  )
}

export default TablePayments
