import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { usePayouts } from 'admin/hooks/use-payout'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { PageTop } from 'components/PageTop'
import { Tabs } from 'components/Tabs'
import { EmptyPayouts } from './EmptyPayouts'
import { PanelInQueue } from './PanelInQueue'
import { PanelProcessed } from './PanelProcessed'

const Payouts = () => {
  const [searchParams] = useSearchParams()
  const { tab = 'ready' } = useParams() as { tab: 'ready' | 'completed' }
  const navigate = useNavigate()

  const {
    data: mostRecentCompletedPayout,
    isLoading: loadingCompletedPayouts,
  } = usePayouts({
    filter: {
      completed: [true],
    },
    pagination: {
      page: 0,
      size: 1,
    },
  })
  const { data: mostRecentPayout, isLoading: loadingReadyPayouts } = usePayouts(
    {
      filter: {
        completed: [false],
      },
      pagination: {
        page: 0,
        size: 1,
      },
    }
  )

  return (
    <MainContent>
      {loadingCompletedPayouts || loadingReadyPayouts ? (
        <PageLoader />
      ) : (
        <Flex stack gap={16}>
          <PageTop title="Payouts" />

          {!mostRecentPayout?.meta.total &&
            !mostRecentCompletedPayout?.meta.total && <EmptyPayouts />}

          {(mostRecentCompletedPayout?.meta.total ||
            mostRecentPayout?.meta.total) && (
            <Tabs
              tab={tab}
              onTabSelect={(tab) => {
                navigate(
                  {
                    pathname: pathTo('payoutsTab', tab),
                    search: searchParams.toString(),
                  },
                  { replace: true }
                )
              }}
            >
              <Tabs.Pane tab="In Queue" id="ready" className="pt-5">
                <PanelInQueue />
              </Tabs.Pane>
              <Tabs.Pane tab="Processed" id="completed" className="pt-5">
                <PanelProcessed />
              </Tabs.Pane>
            </Tabs>
          )}
        </Flex>
      )}
    </MainContent>
  )
}

export { Payouts }
