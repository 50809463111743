import clsx from 'clsx'
import { formatISO } from 'date-fns'
import { compact, omit } from 'lodash'
import { useState, useCallback, useMemo } from 'react'
import { useReleasePayouts } from 'admin/hooks/use-payout'
import { StepConfirmation } from 'admin/pages/Payouts/ModalProcessPayment/StepConfirmation'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalFullSize } from 'components/ModalFullSize'
import { Transaction } from 'types'
import { formatDate } from 'utils/date'
import { StepPayoutDate } from './StepPayoutDate'
import { StepPayoutDestinations } from './StepPayoutDestinations'
import { StepReview } from './StepReview'

interface Props {
  transactions: Transaction[]
  onClose: (isComplete: boolean) => void
}

type Step = 'payout-date' | 'payout-destinations' | 'review' | 'confirmation'
type IPayoutState = {
  date: string
  destinations: Record<string, 'banking' | 'wallet'>
}
const steps: { id: Step; label: string }[] = [
  { id: 'payout-date', label: 'Payout Date' },
  { id: 'payout-destinations', label: 'Payout Destinations' },
  { id: 'review', label: 'Review' },
]
const stepHeaders = {
  'payout-date': 'When should this payout occur?',
  'payout-destinations': 'Where should these payouts go?',
  review: 'Review and confirm payouts',
}

const ModalProcessPayment = ({
  transactions: initialTransactions,
  onClose,
}: Props) => {
  const [transactions, setTransactions] =
    useState<Transaction[]>(initialTransactions)
  const minDate = useMemo(
    () =>
      transactions
        .map(({ date }) => formatDate(date, 'yyyy-MM-dd'))
        .sort()
        .reverse()[0],
    [transactions]
  )
  const [payout, setPayout] = useState<IPayoutState>({
    date: compact([formatISO(new Date(), { representation: 'date' }), minDate])
      .sort()
      .reverse()[0],
    destinations: initialTransactions.reduce(
      (acc, transaction) => ({
        ...acc,
        [transaction.id]: transaction.person?.banking ? 'banking' : 'wallet',
      }),
      {}
    ),
  })
  const [deletingTransactionIds, setDeletingTransactionIds] =
    useState<string[]>()
  const [step, setStep] = useState<Step>('payout-date')
  const { mutate: releasePayouts, isPending: isSaving } = useReleasePayouts()

  const handleNext = useCallback(async () => {
    if (step === 'payout-date') {
      setStep('payout-destinations')
    } else if (step === 'payout-destinations') {
      setStep('review')
    } else if (step === 'review') {
      releasePayouts(
        {
          date: payout.date,
          transactions: Object.entries(payout.destinations).map(
            ([transactionId, destination]) => ({
              id: transactionId,
              bankingId:
                destination === 'banking'
                  ? transactions.find(({ id }) => transactionId === id)?.person
                      ?.banking?.id
                  : null,
            })
          ),
        },
        {
          onSuccess: () => {
            setStep('confirmation')
          },
        }
      )
    }
  }, [payout, transactions, step, onClose])
  const handleBack = useCallback(() => {
    if (step === 'payout-date') {
      onClose(false)
    } else if (step === 'payout-destinations') {
      setStep('payout-date')
    } else if (step === 'review') {
      setStep('payout-destinations')
    }
  }, [step, onClose])
  const handleStepClick = useCallback((nextStep: Step) => {
    setStep(nextStep)
  }, [])

  const handleTransactionDelete = useCallback(() => {
    setTransactions((prevTransactions) =>
      prevTransactions.filter(
        (transaction) => !deletingTransactionIds?.includes(transaction.id)
      )
    )
    setPayout((prevPayout) => {
      return {
        ...prevPayout,
        destinations: omit(
          prevPayout.destinations,
          deletingTransactionIds as string[]
        ),
      }
    })
    setDeletingTransactionIds(undefined)
  }, [deletingTransactionIds])

  return (
    <ModalFullSize
      onClose={() => onClose(false)}
      className="px-[90px] relative !bg-grey-50"
    >
      {step === 'confirmation' ? (
        <StepConfirmation
          transactions={transactions}
          destinations={payout.destinations}
          onClose={() => onClose(true)}
        />
      ) : (
        <Flex>
          <Flex
            stack
            gap={24}
            className="h-full border-solid border-0 border-l-[1px] border-grey-200"
          >
            {steps.map(({ id, label }) => (
              <div
                key={id}
                onClick={() => handleStepClick(id)}
                className={clsx(
                  id === step
                    ? 'pl-[13px] font-bold border-solid border-0 border-l-[3px] border-blue-100 text-grey-900'
                    : '',
                  'text-lg min-w-56 py-1 pl-4 leading-[20px] text-grey-700 cursor-pointer'
                )}
              >
                {label}
              </div>
            ))}
          </Flex>
          <Flex stack gap={20} className="w-full max-w-[720px]">
            <div className="text-xl font-bold text-grey-600">
              Review Batch Payout
            </div>
            <Flex stack gap={32}>
              <Header variant="h2" className="leading-8">
                {stepHeaders[step]}
              </Header>
              {step === 'payout-date' && (
                <StepPayoutDate
                  minDate={minDate}
                  values={payout}
                  onChange={(values) =>
                    setPayout((prevPayout) => ({ ...prevPayout, ...values }))
                  }
                  onNext={handleNext}
                  onBack={handleBack}
                />
              )}
              {step === 'payout-destinations' && (
                <StepPayoutDestinations
                  transactions={transactions}
                  destinations={payout.destinations}
                  onChange={(destination) =>
                    setPayout({
                      ...payout,
                      destinations: { ...payout.destinations, ...destination },
                    })
                  }
                  onDelete={setDeletingTransactionIds}
                  onNext={handleNext}
                  onBack={handleBack}
                />
              )}
              {step === 'review' && (
                <StepReview
                  saving={isSaving}
                  transactions={transactions}
                  destinations={payout.destinations}
                  onNext={handleNext}
                  onBack={handleBack}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
      {deletingTransactionIds && (
        <ModalDelete
          resource="transaction"
          onDelete={handleTransactionDelete}
          onCancel={() => setDeletingTransactionIds(undefined)}
        />
      )}
    </ModalFullSize>
  )
}

export { ModalProcessPayment }
