import {
  useState,
  useEffect,
  isValidElement,
  cloneElement,
  ReactElement,
  forwardRef,
  ForwardedRef,
  memo,
} from 'react'

enum IconName {
  active = 'active',
  addCircle = 'add-circle',
  addFromTemplate = 'add-from-template',
  addNote = 'add-note',
  addPerson = 'add-person',
  address = 'address',
  approvedCircle = 'approved-circle',
  applicationSettings = 'application-settings',
  archive = 'archive',
  arrowDown = 'arrow-down',
  arrowDownFilled = 'arrow-down-filled',
  arrowLeft = 'arrow-left',
  arrowLeftLong = 'arrow-left-long',
  arrowRight = 'arrow-right',
  arrowRightLong = 'arrow-right-long',
  arrowUp = 'arrow-up',
  attention = 'attention',
  automations = 'automations',
  bank = 'bank',
  blockquote = 'blockquote',
  borrower = 'borrower',
  boldListUl = 'bold-list-ul',
  briefcase = 'briefcase',
  calendar = 'calendar',
  check = 'check',
  checklist = 'checklist',
  circleCheck = 'circle-check',
  close = 'close',
  closeLarge = 'close-large',
  closingDate = 'closing-date',
  collectPayment = 'collect-payment',
  comments = 'comments',
  company = 'company',
  customField = 'custom-field',
  dashboard = 'dashboard',
  delete = 'delete',
  dndCross = 'dnd-cross',
  dndHandler = 'dnd-handler',
  doubleArrowRight = 'double-arrow-right',
  documentBlank = 'document-blank',
  documentDollar = 'document-dollar',
  documentEdit = 'document-edit',
  documentInfo = 'document-info',
  documentPlus = 'document-plus',
  documentRefresh = 'document-refresh',
  dollar = 'dollar',
  download = 'download',
  draft = 'draft',
  duplicate = 'duplicate',
  edit = 'edit',
  ellipses = 'ellipses',
  email = 'email',
  entity = 'entity',
  error = 'error',
  filter = 'filter',
  fund = 'fund',
  hamburger = 'hamburger',
  home = 'home',
  individual = 'individual',
  investmentConfirmed = 'investment-confirmed',
  info = 'info',
  insurance = 'insurance',
  indicator = 'indicator',
  investor = 'investor',
  link = 'link',
  linkExternal = 'link-external',
  listOl = 'list-ol',
  listUl = 'list-ul',
  loaderSpinner = 'loader-spinner',
  loan = 'loan',
  location = 'location',
  logout = 'logout',
  magnifyingGlass = 'magnifying-glass',
  manager = 'manager',
  marketplace = 'marketplace',
  mergeField = 'merge-field',
  message = 'message',
  minus = 'minus',
  minusCircle = 'minus-circle',
  moveDown = 'move-down',
  moveFile = 'move-file',
  moveUp = 'move-up',
  noPreview = 'no-preview',
  note = 'note',
  notification = 'notification',
  number = 'number',
  offering = 'offering',
  paperClip = 'paper-clip',
  pause = 'pause',
  payout = 'payout',
  pen = 'pen',
  percentage = 'percentage',
  person = 'person',
  pipeline = 'pipeline',
  play = 'play',
  plus = 'plus',
  plusCircle = 'plus-circle',
  print = 'print',
  product = 'product',
  quote = 'quote',
  refer = 'refer',
  report = 'report',
  requestDocument = 'request-document',
  restore = 'restore',
  reverse = 'reverse',
  roles = 'roles',
  send = 'send',
  settings = 'settings',
  share = 'share',
  signature = 'signature',
  star = 'star',
  support = 'support',
  tableSort = 'table-sort',
  task = 'task',
  team = 'team',
  text = 'text',
  textBold = 'text-bold',
  textH1 = 'text-h1',
  textH2 = 'text-h2',
  textH3 = 'text-h3',
  textH4 = 'text-h4',
  textH5 = 'text-h5',
  textH6 = 'text-h6',
  textItalic = 'text-italic',
  textParagraph = 'text-paragraph',
  textUnderline = 'text-underline',
  textAlignJustify = 'text-align-justify',
  textAlignCenter = 'text-align-center',
  textAlignRight = 'text-align-right',
  textAlignLeft = 'text-align-left',
  twoSheets = 'two-sheets',
  upload = 'upload',
  uploadCloud = 'upload-cloud',
  valuation = 'valuation',
  vendor = 'vendor',
  versionHistory = 'version-history',
  view = 'view',
  viewer = 'viewer',
  wallet = 'wallet',
  watch = 'watch',
  workspace = 'workspace',
}

interface IconProps {
  name: IconName
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'
  className?: string
  onClick?: () => void
}

const cache = new Map<string, ReactElement>()

const Icon = memo(
  forwardRef(
    (
      { name, size = 'md', className, ...rest }: IconProps,
      ref: ForwardedRef<SVGElement | null>
    ) => {
      const [icon, setIcon] = useState(null)
      const sizeMap = {
        sm: '10px',
        md: '16px',
        lg: '20px',
        xl: '36px',
        xxl: '64px',
        xxxl: '82px',
      }

      useEffect(() => {
        let _mounted = true
        const importIcon = async () => {
          const svg = cache.has(name)
            ? cache.get(name)
            : await import(`./svg/${name}.tsx`)
          if (_mounted) {
            // https://github.com/vitejs/vite/issues/1830
            setIcon(svg.default.default || svg.default)
            cache.set(name, svg)
          }
        }
        importIcon()

        return () => {
          setIcon(null)
          _mounted = false
        }
      }, [name])

      if (!isValidElement(icon)) {
        return (
          <svg
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={sizeMap[size]}
            height={sizeMap[size]}
            className={className}
            ref={ref as any}
            {...rest}
          />
        )
      }

      return cloneElement(icon as ReactElement, {
        'aria-hidden': true,
        role: 'img',
        className,
        width: sizeMap[size],
        height: sizeMap[size],
        ref,
        ...rest,
      })
    }
  )
)
Icon.displayName = 'Icon'

export type { IconProps }
export { IconName }
export default Icon
